.logo {
    /*height: 48px;*/
    width: 262px;
}

/* tablets */
@media only screen and (max-width: 1200px) {

    .logo{
        height: 28px;
        width: 152px;
    }
}