.appBar {
    z-index: 2
}

.appBar {
    background-color: #fff !important;
    border-bottom: 1px solid #E5E5E5;
    //padding: 10px;
}

.appBarElevated img{
    //filter:  brightness(0) invert(1);
}

.toolbar {
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.headerInner{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1440px;
    margin: 17px auto;
}

.authMenu{
    display: flex;
    align-items: center;
    justify-content: right;

    @media only screen and (max-width: 900px) {
        display: none;
    }


    .headerNav{
        width: 530px;
        margin-right: 190px;

        a {
            min-width: initial;
            font-size: 18px;
            font-weight: 400;
            line-height: 24px;
            color: #170B01;
            padding: 0px 20px;

            &:hover {
                color: #3ABEF9;
                background: none;
            }
        }
    }

    .signupBtn{
        color: #000;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;

        &:hover {
            background: inherit;
            border-color: inherit;
        }
    }

    .loginBtn {
        font-family: 'Arial', sans-serif;
        font-size: 18px;
        color: #FFFFFF;
        font-weight: 500;
        line-height: 18px;
        background: #3ABEF9;
        border-radius: 30px;
        border: 3px solid #3ABEF9;
        text-transform: capitalize;
        cursor: pointer;
        transition: background 0.3s, color 0.3s, border-color 0.3s;
        padding: 16px 48px 16px 48px;
        text-wrap: nowrap;


        &:hover {
            background: #2788b3;
            border-color: #2788b3;
        }
    }
}


.authMobileMenu{
    width: 100%;
    display: none;
    align-items: center;
    justify-content: right;

    @media only screen and (max-width: 900px) {
        display: flex;
    }

    .authMobileMenu svg{
        color: #000;
        font-size: 40px;
    }
}

.mobNavLink{
    font-size: 20px;
    padding: 0px 20px;
    margin: 0px;
    justify-content: flex-start;
    text-transform: capitalize;
    color: #000 !important;
}

.logo {

}


