.container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 100;
    text-align: center;
    font-size: 1rem;
    background: beige;

    --b:10; /* control the border */
    border: solid #e30404;
    border-width: calc(var(--b)*1px) 0 0 0;
    border-image: repeating-linear-gradient(-45deg,#0000 0 4px, #ea976a 0 8px) var(--b);
}


.container a{
    color: #0A0A0A;
    text-decoration: underline;
}

.innerContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button{
    margin-left: 3rem;
    padding: 7px 20px;
    font-size: 1rem;
    text-transform: uppercase;
    wrap: nowrap;
}


@media only screen and (max-width: 1280px) {
    .container{
        /*font-size: 1.2rem;*/
        /*padding: 0.8rem;*/
    }

    .innerContainer{
        flex-direction: row;
    }

    .button {
        margin-left: 20px;
        padding: 10px 20px;
        font-size: 0.8rem;
    }
}

@media only screen and (max-width: 900px) {
    .container{
        /*font-size: 1rem;*/
        /*padding: 0.8rem;*/
    }

    .innerContainer{
        flex-direction: column;
    }

    .button {
        margin-left: 0px;
        padding: 10px 20px;
        font-size: 0.8rem;
    }
}