
.footerContainer {
  position: relative;
  width: 100%;
  background-repeat: no-repeat;
  margin-top: 80px;

  .footerInnerContainer {
    position: relative;
    z-index: 1;
    color: #170B01;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;

    @media (max-width: 900px) {
      flex-direction: column;
    }

    .footerItemNav{
      width: 70%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: 900px) {
        display: none;
      }
    }

    .footerItem{
      display: flex;
      flex-direction: column;

      .logo{
        margin-top: -10px;
        margin-bottom: 15px;
      }

      h6{
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 20px;
        color: #170B01;
      }

      a{
        margin-top: 14px;
        font-weight: 400;
      }
    }
  }

}



