@font-face {
    font-family: 'GeneralSans-Light';
    src: url('/fonts/GeneralSans/GeneralSans-Light.woff2') format('woff2'),
    url('/fonts/GeneralSans/GeneralSans-Light.woff') format('woff'),
    url('/fonts/GeneralSans/GeneralSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'GeneralSans-LightItalic';
    src: url('/fonts/GeneralSans/GeneralSans-LightItalic.woff2') format('woff2'),
    url('/fonts/GeneralSans/GeneralSans-LightItalic.woff') format('woff'),
    url('/fonts/GeneralSans/GeneralSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}
@font-face {
    font-family: 'GeneralSans-Regular';
    src: url('/fonts/GeneralSans/GeneralSans-Regular.woff2') format('woff2'),
    url('/fonts/GeneralSans/GeneralSans-Regular.woff') format('woff'),
    url('/fonts/GeneralSans/GeneralSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'GeneralSans-Italic';
    src: url('/fonts/GeneralSans/GeneralSans-Italic.woff2') format('woff2'),
    url('/fonts/GeneralSans/GeneralSans-Italic.woff') format('woff'),
    url('/fonts/GeneralSans/GeneralSans-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}
@font-face {
    font-family: 'GeneralSans-Medium';
    src: url('/fonts/GeneralSans/GeneralSans-Medium.woff2') format('woff2'),
    url('/fonts/GeneralSans/GeneralSans-Medium.woff') format('woff'),
    url('/fonts/GeneralSans/GeneralSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'GeneralSans-MediumItalic';
    src: url('/fonts/GeneralSans/GeneralSans-MediumItalic.woff2') format('woff2'),
    url('/fonts/GeneralSans/GeneralSans-MediumItalic.woff') format('woff'),
    url('/fonts/GeneralSans/GeneralSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'GeneralSans-Regular', sans-serif;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

